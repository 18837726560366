import _ from 'underscore'
import { constants } from '../constants'

export const hashColor = (text) => {
  if (text === undefined || text === null) return 'disabled'

  if (text.length === 0) return 'disabled'
  else {
    let sum = 0
    for (let i = 0; i < text.length; i++) {
      sum += text.charCodeAt(i)
    }

    if (sum % 10 === 0) return '#27ae61'
    else if (sum % 10 === 1) return '#16a086'
    else if (sum % 10 === 2) return '#f1c40f'
    else if (sum % 10 === 3) return '#f39c11'
    else if (sum % 10 === 4) return '#3598db'
    else if (sum % 10 === 5) return '#298fb9'
    else if (sum % 10 === 6) return '#9a59b5'
    else if (sum % 10 === 7) return '#e67f22'
    else if (sum % 10 === 8) return '#c1392b'
    else if (sum % 10 === 9) return '#34495e'
    else if (sum % 10 === 10) return '#1avc9c'

    // if (sum % 10 === 0) return 'success'
    // else if (sum % 10 === 1) return 'danger'
    // else if (sum % 10 === 2) return 'warning'
    // else if (sum % 10 === 3) return 'secondary'
    // else if (sum % 10 === 4) return 'blue-500'
    // else if (sum % 10 === 5) return 'yellow-400'
    // else if (sum % 10 === 6) return 'orange-400'
    // else if (sum % 10 === 7) return 'pink-400'
    // else if (sum % 10 === 8) return 'purple-400'
    // else if (sum % 10 === 9) return 'blue-400'
    // else if (sum % 10 === 10) return 'red-400'
  }

  return 'disabled'
}

export const extractInitials = (name) => {
  // if (name === undefined || name === null || name.trim() === '') return ''

  // const parts = name.split(' ')

  // if (parts.length === 0) return ''
  // else if (parts.length === 1) return name[0]
  // else if (parts.length > 1) return `${parts[0][0].toUpperCase()}${parts[parts.length - 1][0].toUpperCase()}`
  // else return ''

  if (name === undefined || name === null || name.trim() === '') return ''

  const parts = name.split(' ')

  if (parts.length === 0) {
    return ''
  } else if (parts.length === 1) {
    return name[0]
  } else if (parts.length > 1) {
    try {
      return `${parts[0][0].toUpperCase()}${parts[parts.length - 1][0].toUpperCase()}`
    } catch (e) {
      console.log('extractInitials->name cannot set to uppercase: ', name)
      console.log(e)
      const shortName = `${parts[0][0]}${parts[1][0]}`
      return shortName
    }
  } else return ''
}

export const taskAttachmentPicture = (taskId, attachment) => {
  console.log('attachment: ', attachment.url)
  return attachment.url === ''
    ? `${constants.serviceBaseUrl}/tasks/${taskId}/attachments/${attachment.id}?rnd=${_.random(10000, 99999)}`
    : `${constants.source}${attachment.url}`
}
