// export const constants = {
//   auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
//   auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
//   auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
//   auth0RedirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL,
//   serviceBaseUrl: process.env.REACT_APP_SERVICE_BASE_URL,
//   maxProfileIcons: 3,
//   protectedSocketUri: process.env.REACT_APP_SERVICE_SOCKET_URL,
// source: `https://salext-todo-data.s3.eu-north-1.amazonaws.com/`
// }

export const constants = {
  version: 'Beta',
  auth0ClientId: 'sqVnSZpmzHhsiMaTEca7ZAyvcNcphrhe',
  auth0Domain: 'https://salext-dev.eu.auth0.com',
  auth0Audience: 'https://salext.net',
  auth0RedirectUri: 'https://console.dev.salext.net/authenticate',
  serviceBaseUrl: 'https://service.dev.salext.net',
  maxProfileIcons: 3,
  protectedSocketUri: 'https://service.dev.salext.net',
  source: `https://salext-todo-data.s3.eu-north-1.amazonaws.com/`
}

// export const constants = {
//   version: 'Alpha',
//   auth0ClientId: 'sqVnSZpmzHhsiMaTEca7ZAyvcNcphrhe',
//   auth0Domain: 'https://salext-dev.eu.auth0.com',
//   auth0Audience: 'https://salext.net',
//   auth0RedirectUri: 'https://console.alpha.salext.net/authenticate',
//   serviceBaseUrl: 'https://service.alpha.salext.net',
//   maxProfileIcons: 3,
//   protectedSocketUri: 'https://service.alpha.salext.net',
//   source: `https://salext-todo-data.s3.eu-north-1.amazonaws.com/`
// }

// export const constants = {
//   devMode: 'true',
//   version: 'Dev',
//   auth0ClientId: 'sqVnSZpmzHhsiMaTEca7ZAyvcNcphrhe',
//   auth0Domain: 'https://salext-dev.eu.auth0.com',
//   auth0Audience: 'https://salext.net',
//   auth0RedirectUri: 'http://console.salext.io:8000/authenticate',
//   serviceBaseUrl: 'http://service.salext.io:3000',
//   maxProfileIcons: 3,
//   protectedSocketUri: 'http://service.salext.io:3000',
//   source: `https://salext-todo-data.s3.eu-north-1.amazonaws.com/`
// }
